let awsRegion = 'eu-west-1';

export default {
 api_urls: {
   reporting: 'https://uv9bh6a7jb.execute-api.eu-west-1.amazonaws.com/api',
   geojson: 'https://fm7ecluwl2.execute-api.eu-west-1.amazonaws.com/api',
   overlays: 'https://zqq7dxbeag.execute-api.eu-west-1.amazonaws.com/api',
 },
 google_api_url: 'AIzaSyDI0B09O3FFEn1-usWMdtJAzat9O7SbvR4',
 main_company_term_id: 6,
 crm_base_url: 'https://crm.testing.macquarie.forwoodsafety.com',
 forwood_id_url: 'https://id.testing.macquarie.forwoodsafety.com?redirect_uri=https://geoeditor.testing.macquarie.forwoodsafety.com',
 custom_tile_url: 'https://mapoverlays.geoeditor.testing.macquarie.forwoodsafety.com',
 Auth: {
   userPoolId: 'eu-west-1_DZdYUBRue',
   userPoolWebClientId: '44feqsrirrdg2q18qa4sd9mj1m',
   cookieStorage: {
     domain: '.testing.macquarie.forwoodsafety.com',
     secure: true,
   },
 },
 intercom_enabled: 'True',
 env: 'testing'
};
